import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentCheckbox.figmaUrl.ios} codeUrl={checklists.componentCheckbox.codeUrl.ios} checklists={checklists.componentCheckbox.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Create checkbox button with or without label. Checkbox is a component that allows the user to make a choice between one of two possible mutually exclusive options such as ‘yes’ or ‘no’ to a question. With checkbox, user can check or uncheck in one box. How to import your theme: Legion iOS UIKit offers four themes: `}<inlineCode parentName="p">{`ThemeAGR`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeIHS`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeLGN`}</inlineCode>{`, and `}<inlineCode parentName="p">{`ThemeMyTEnS`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <p>{`The checkbox can be used without any parameter`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox()
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`LGNButton have 2 checkbox variants:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Check`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Min`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-check.png",
              "alt": null,
              "title": "LGNCheckbox Check"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-min.png",
              "alt": null,
              "title": "LGNCheckbox Min"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Check`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox = LGNCheckbox()
checkbox.variant = .check
checkbox.size = .lg
checkbox.checked = true
checkbox.textLabel = "Label"
`}</code></pre>
    <h3>{`Min`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox2 = LGNCheckbox()
checkbox2.variant = .min
checkbox2.size = .lg
checkbox2.checked = true
checkbox2.textLabel = "Label"
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`You can customize the size of the button via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` enum, where `}<inlineCode parentName="p">{`BaseCheckbox.Size`}</inlineCode>{` includes `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{` and `}<inlineCode parentName="p">{`sm`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Large`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Medium`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Small`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-large.png",
              "alt": null,
              "title": "LGNCheckbox Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-medium.png",
              "alt": null,
              "title": "LGNCheckbox Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-small.png",
              "alt": null,
              "title": "LGNCheckbox Small"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Large`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox = LGNCheckbox()
checkbox.variant = .check
checkbox.size = .lg
checkbox.checked = true
checkbox.textLabel = "Label"
`}</code></pre>
    <h3>{`Medium`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox2 = LGNCheckbox()
checkbox2.variant = .check
checkbox2.size = .md
checkbox2.checked = true
checkbox2.textLabel = "Label"
`}</code></pre>
    <h3>{`Small`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox3 = LGNCheckbox()
checkbox3.variant = .check
checkbox3.size = .sm
checkbox3.checked = true
checkbox3.textLabel = "Label"
`}</code></pre>
    <div className="divi" />
    <h2>{`Disabled`}</h2>
    <p>{`You can also disable user interaction on `}<inlineCode parentName="p">{`LGNCheckbox`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Checked`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Unchecked`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-disabled-checked.png",
              "alt": null,
              "title": "LGNCheckbox Disable Checked"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-uikit/ios-uikit-checkbox-disabled-unchecked.png",
              "alt": null,
              "title": "LGNCheckbox Disable Unchecked"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Checked`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox = LGNCheckbox()
checkbox.variant = .check
checkbox.size = .lg
checkbox.checked = true
checkbox.textLabel = "Label"
checkbox.disable = true
`}</code></pre>
    <h3>{`Unchecked`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let checkbox2 = LGNCheckbox()
checkbox2.variant = .check
checkbox2.size = .lg
checkbox2.checked = false
checkbox2.textLabel = "Label"
checkbox2.disable = true
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`checked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state of checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determine if the checkbox disabled or not.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.md`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the checkbox that describes it.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Variation of checkbox image.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.check`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      